html,
body {
  font-family: Roboto;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  &::-webkit-scrollbar {
    display: none;
  }
  .css-13xfq8m-MuiTabPanel-root {
    padding: 16px 48px 0 48px;
  }
  .MuiLinearProgress-root {
    top: 0;
    position: sticky;
    &.load {
      opacity: 1;
    }
    &.un__load {
      opacity: 0;
    }
  }
}
